import request from './request'
// QueryDateSlotDto
import { QueryPageDto } from './dto'

// 获取被派遣单位列表
export interface InvoiceDto {
    actual_money: string
    company_name: string
    create_time: string
    id: number
    invoice_date: string
    invoice_subject: string
    remark: string
    tax_number: string
}
interface GetInvoiceDto extends QueryPageDto {
    startTime: string
    endTime: string
}
export function getInvoice (
    params: GetInvoiceDto
): Promise<{ data: InvoiceDto[]; total: number }> {
    return request.get('/invoice/list', {
        params
    })
}

// 充值记录  /client/recharge/list

interface GetRechargeEecordListDto extends QueryPageDto {
    startTime: string
    endTime: string
    payType: string
}
export function getRechargeEecordList (
    params: GetRechargeEecordListDto
): Promise<{ data: InvoiceDto[]; total: number }> {
    return request.get('/recharge/list', {
        params
    })
}
export interface GetInvoiceInfoDto {
    company_name: string
    cut_date: string
    surplus: number
    tax_number: string
}
// 发票申请页的信息 /invoice/applyInfo
export function getInvoiceInfo (): Promise<GetInvoiceInfoDto> {
    return request.get('/invoice/applyInfo')
}

// 申请发票 invoice/apply
export function getApplyInvoice (data: {
    email: string
    mobile: string
}): Promise<[]> {
    return request.post('/invoice/apply', data)
}

// 充值账户
export interface AccountDto {
    account_id: number
    name: string
    account_name: string
    bank_name: string
    account_number: string
    tax_number: string
    background: string
}
export function getPayAccount (): Promise<AccountDto> {
    return request.get('/recharge/payments')
}

// 支付宝充值
export function getPayUrl (params: {
    payment: string
    money: string
}): Promise<{
    form: string
}> {
    return request.get('/recharge/pagePay', {
        params
    })
}

// 期初账单
export interface BillDto {
  policy_cycle_bill_id: string
  client: []
  plan_name: string
  origin_plan_name: number
  start_date: string
  end_date: string
  price: string
  month_count: string
  person_count: string
  frist_premium: string
  help_premium: string
  premium: string
}
export function getPolicyCycleBillList (
    params: QueryPageDto
): Promise<{ data: BillDto[]; total: number }> {
    return request.get('/policyCycleBill/list', {
        params
    })
}
