
import { defineComponent } from 'vue'
import { getRechargeEecordList, InvoiceDto } from '@/api/invoice'
export default defineComponent({
    name: 'AechargeRecord',
    components: {},
    data () {
        return {
            searchValue: '',
            payType: '',
            searchText: '',
            pageNum: 1,
            pageSize: 10,
            payDateSolt: '',
            total: 0,
            tableData: [] as InvoiceDto[],
            payDateStart: '',
            payDateEnd: '',
            options: [
                { value: '10', label: '支付宝' },
                { value: '20', label: '微信' },
                { value: '30', label: '公对公转账' },
                { value: '50', label: '补贴' }
            ]
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
        async handleCurrentChange () {
            const result = await getRechargeEecordList({
                payType: this.payType,
                startTime: this.payDateStart,
                endTime: this.payDateEnd,
                page: this.pageNum,
                pageSize: this.pageSize
            })
            this.total = result.total
            this.tableData = result.data
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search () {
            // this.searchValue = this.searchText
            this.payDateStart = this.payDateSolt ? this.payDateSolt[0] : ''
            this.payDateEnd = this.payDateSolt ? this.payDateSolt[1] : ''
            this.pageNum = 1
            this.handleCurrentChange()
        },
        handleChange (value: string) {
            this.payType = value
        }
    }
})
